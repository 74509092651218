<!--
 * @Descripttion: 登录页面
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-12-23 09:40:24
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-09-19 14:21:58
-->
<template>
    <div class="defaultV2-signIn" v-loading="loading" 
        element-loading-text="登录中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)">
        <star-background />
        <div class="line"></div>
        <div class="sing-wrap">
            <div class="right-plan">
                <div class="title">
                    <div class="name">登录</div>
                </div>
                <div class="loginForm">
                    <el-form ref="form" :model="loginForm" label-position='top'>
                        <el-form-item label="账号">
                            <el-input v-model="loginForm.username" placeholder="请输入账号"></el-input>
                        </el-form-item>
                        <el-form-item label="密码">
                            <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <el-form-item label="验证码">
                            <div class="code-wrap">
                                <el-input id="black" class="code" v-model="loginForm.code" placeholder="请输入验证码"></el-input>
                                <div class="code-tig" @click="clickCode">
                                    <img v-if="codeUrl != ''" class="img" :src="codeUrl" />
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="sign-btn" @click="handleLogin">登录</div>
            </div>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import StarBackground from './starBackground'
import axios from "axios";
export default {
    components: { StarBackground },
    data() {
        return {
            loginForm:{
                username:'',
                password:'',
                code: '',
            },
            codeUrl: '',
            loading: false,
        }
    },
    beforeCreate: function() {
        document.getElementsByTagName('body')[0].className = 'body-bg'
    },
    mounted(){
        this.keyupSubmit();
        this.getVerify();
    },
    destroyed(){
      //取消键盘监听事件
        document.onkeydown = null
    },
    methods: {
        keyupSubmit() { 
            document.onkeydown = (e) => {
                let _key = window.event.keyCode;
                if (_key === 13&&!this.clickState) {
                    this.handleLogin();
                }
            };
        },
        // 点击获取验证码
        clickCode(){
            this.getVerify();
        },
        getVerify(){
            this.codeUrl = ''
            let url = common.apiServer+'/customerservicev1/auth/verify'
            axios({
                url: url,
                method: 'get',
                responseType: "blob"
            }).then((res)=> {
                this.$set(this,'codeUrl',res.config.url)
            })
        },
        // 登录
        handleLogin(){
            if(this.loginForm.username === ''){
                this.$message.warning('请输入账号');
                return
            }
            if(this.loginForm.password === ''){
                this.$message.warning('请输入密码');
                return
            }
            if(this.loginForm.code === ''){
                this.$message.warning('请输入验证码');
                return
            }
            let params = {
                username: this.loginForm.username,
                password: this.loginForm.password,
                captcha: this.loginForm.code
            }
            this.loading = true;
            common.connect("/customerservicev1/auth/login",params,(res)=>{
                if(res.isSuccess == 1){
                    localStorage.setItem('token', res.data.kf_token);
                    localStorage.setItem('userInfo', JSON.stringify(res.data));
                    if(res.data.kf_level == 3){
                        this.$router.replace("/posterDataReport");
                    }else if(res.data.kf_level == 4){
                        this.$router.replace("/marketDataReport");
                    }else if(res.data.kf_level == 5){
                        this.$router.replace("/sceneDataReport");
                    }else if(res.data.kf_level == 2){
                        this.$router.replace("/serviceDataReport");
                    }else{
                        this.$router.replace("/functionUsageReport");
                    }
                    document.getElementById('black').focus();
                    this.$message.success("登录成功");
                }
                this.getVerify();
                this.loginForm.code = '';
                this.loading = false;
            });
        },
    }
}
</script>

<style lang="scss">
.body-bg {
    background-attachment: fixed;
    overflow: hidden;
}
.defaultV2-signIn{
    width: 100%;
    height: 100%;
    // background-image: linear-gradient(to right, #69858B , #236470);
    background-image: url(../../assets/background-image.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .line{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
    }
    .sing-wrap{
        display: flex;
        .right-plan{
            width:480px;
            height: 100%;
            // text-align: center;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0px 0px 1px #ccc;
            padding: 20px 40px 50px 40px;
            box-sizing: border-box;
            z-index: 999;
            .title{
                width: 100%;
                font-size: 25px;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px saddlebrown solid 1px;
                .name{
                    font-weight: 600;
                    margin-top: 15px;
                }
            }
            .loginForm{
                .el-form-item__label{
                    line-height: 30px;
                    padding:0 0 0 0;
                    color: #7D9EB5;
                }
                .el-form-item{
                    margin-bottom: 15px;
                }
                .el-input__inner{
                    border: 1px solid #CCDFEA;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 5px;
                }
                .el-input__inner:focus{
                    border-color: #CCDFEA
                }
                .code-wrap{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .code{
                        width: calc(100% - 130px);
                    }
                    .code-tig{
                        width: 120px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        border-radius: 6px;
                        border: 1px solid #ddd;
                        overflow: hidden;
                        cursor: pointer;
                        .img{
                            width: 110%;
                            height: 110%;
                            vertical-align: bottom;
                        }
                    }
                }
            }
            .sign-btn{
                color: #fff;
                border-radius: 5px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                // background-image: linear-gradient(to right, #40CBD1 , #07BDD3);
                background-color: #11A1FD;
                margin-top: 25px;
                cursor: pointer;
                font-size: 16px;
                &:hover{
                    box-shadow: 0px 0px 2px #ccc;
                    background-color: #0E89D7;
                }
            }
        }
    }

}
</style>